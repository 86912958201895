.team {
  padding: 0 150px;
  min-height: 100vh;
  background-image: url(/static/media/team-graphic.7e5dca28.svg);
  background-repeat: no-repeat;
  background-size: 100vh;
  background-position: center;
  position: relative;
  overflow-x: hidden;
  .blue {
    position: absolute;
    z-index: -1;
    width: 700px;
    bottom: 0;
    right: -120px;
  }

  .orange {
    position: absolute;
    top: -70px;
    left: -180px;
    z-index: -1;
    width: 580px;
  }

  .blue-text {
    color: #3d8efb;
  }

  h2 {
    margin: 30px 0;
  }

  p {
    font-size: 16px;
  }

  .profiles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      height: 200px;
    }

    .text {
      text-align: left;
      padding: 0 40px;
      line-height: 30px;
    }
    .profile {
      min-height: 530px;
      max-height: fit-content;
      width: 380px;
      background: white;
      box-shadow: 0px 4px 4px 0px #0000001f;
      border-radius: 10px;
      padding: 3em 0;
      margin: 2em;

      p {
        color: var(--color-secondary);
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .team {
    padding: var(--small-padding);
  }

  .blue {
    display: none;
  }

  .orange {
    display: none;
  }
}

@media (max-width: 767.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .team {
    padding: var(--mobile-padding);

    .profiles {
      .profile {
        margin: 2em 0;
      }
    }
  }
}
