.footer {
  padding: var(--large-padding);
  display: flex;
  text-align: left;
  align-items: flex-start;
  padding-top: 15em;
  justify-content: space-between;
  height: 300px;
  flex-wrap: wrap;

  p {
    margin: 0;
    letter-spacing: 0;
  }

  .title {
    font-weight: 300;
    color: black;
  }

  .link {
    color: var(--color-primary);
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
  }

  .inquiries {
    margin-right: 6em;
    margin-bottom: 3em;
  }

  .social {
    margin-bottom: 3em;

    b {
      display: flex;

      p {
        margin-right: 20px;
      }
    }
  }
}
.location {
  color: var(--color-secondary);
  margin-bottom: 3em;
  cursor: pointer;
}

.left {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .footer {
    padding: var(--small-padding);
    padding-top: 15em;
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .footer {
    padding: var(--mobile-padding);
    padding-top: 15em;
  }
}
