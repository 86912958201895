@import "./global/scss-variables.scss";
@import "./global/css-variables.scss";

.App {
  text-align: center;
}

@font-face {
  font-family: Manrope;
  font-weight: 100;
  src: url("../fonts/Manrope-Light.otf") format("opentype");
}

@font-face {
  font-family: Manrope;
  font-weight: 200;
  src: url("../fonts/Manrope-Regular.otf") format("opentype");
}

@font-face {
  font-family: Manrope;
  font-weight: 300;
  src: url("../fonts/Manrope-Bold.otf") format("opentype");
}

@font-face {
  font-family: Manrope;
  font-weight: 400;
  src: url("../fonts/Manrope-ExtraBold.otf") format("opentype");
}

body {
  font-family: "Manrope";
  font-weight: 200;
}

h1 {
  font-size: var(--large);
}

h2 {
  font-size: var(--medium);
}

p {
  font-size: var(--small);
  letter-spacing: 0.5px;
  line-height: 30px;
}

@media (max-width: 575.98px) {
  h1 {
    font-size: calc(#{var(--large)} * 0.75);
  }

  h2 {
    font-size: calc(#{var(--medium)} * 0.75);
  }

  // p {
  //   font-size: calc(#{var(--small)}* 0.75);
  // }
}
