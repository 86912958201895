.sign-up {
  display: flex;
  margin-top: 10em;

  h1 {
    color: #3d8efb;
    font-weight: 300;
  }

  .graphic {
    flex: 1;
    width: 540px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    padding-top: 15em;
    img {
      height: 45em;
    }
  }

  .text {
    flex: 2;
    text-align: left;
    padding-left: 5em;
    .cost {
      p {
        font-weight: 100;
        line-height: unset;
      }
      .large {
        font-size: 120px;
      }

      .button {
        background: linear-gradient(
          90deg,
          var(--color-primary),
          rgba(65, 146, 255, 1) 100%
        );

        border-radius: 20px;
        padding: 7px 0;
        width: 200px;
        display: flex;
        justify-content: center;
        margin-top: 2em;

        h2 {
          color: white;
          margin: 0;
          font-weight: 300;
          font-size: 22px;
        }
      }

      .checkbox-area {
        display: flex;
        margin-bottom: 1em;
        cursor: pointer;
        margin-top: 10px;
        width: 100%;

        input {
          // height: 20px;
          // width: 20px;
          margin-right: 10px;
        }
        p {
          margin: 0;
          font-weight: 200;
          letter-spacing: unset;
        }
      }

      .form {
        .section {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 5em;

          .credit-card {
            margin-top: 1em;
            max-width: 80%;
          }
          .title {
            font-weight: 100;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .sign-up {
    padding: var(--small-padding);

    .graphic {
      display: none;
      img {
        display: none;
      }
    }

    .text {
      padding: 0;
      max-width: 100%;
    }
  }
}

@media (max-width: 767.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .sign-up {
    padding: var(--mobile-padding);
    .text {
      .cost {
        .large {
          font-size: 60px;
        }
      }
    }
  }
}
