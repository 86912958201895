.whats-in-a-makebox {
  margin-top: 5em;
  height: fit-content;

  .box {
    max-width: 100vw;
  }

  h1 {
    margin: 0;
  }

  h2 {
    margin-bottom: 10px;
  }

  .blue-text {
    color: #3d8efb;
  }
  .gear {
    animation: rotation 10s infinite linear;
  }
  .makebox-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px 0;
    .feature {
      width: 15em;
      text-align: left;
      margin: 20px 90px;

      p {
        color: var(--color-secondary);
      }
    }
  }
}

@media (max-width: 575.98px) {
  .whats-in-a-makebox {
    // margin-top: 2em;

    .makebox-features {
      margin: 40px 0;
      .feature {
        text-align: center;
        margin: 20px 0;
      }
    }
    .text {
      br {
        display: none;
      }
      .button {
        width: 151px;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
