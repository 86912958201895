.textbox-div {
  margin: 1em 1em 0 0;

  .small {
    width: 100px !important;
  }
  .large {
    width: 80% !important;
    font-size: var(--small) !important;
  }
  .textbox {
    min-width: 200px;
    max-width: 250px;
    height: 40px;
    border: solid #e3e3e3 1px;
    border-radius: 5px;
    font-family: "Manrope";
    font-weight: 100;
    padding: 0 10px;
    box-shadow: none;
  }

  .promo {
    display: flex;
    align-items: center;

    color: var(--color-primary);
  }

  .title {
    width: fit-content;
    margin: 5px 0;
    font-weight: 300 !important;
  }
}

.break {
  width: 100%;
}

#verify-code {
  font-weight: 300;
  font-size: 14px;
  margin-left: 14px;
}
