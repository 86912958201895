.landing-page {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 150px;

  .text {
    display: flex;
    flex-direction: column;
    text-align: left;

    h1 {
      margin-bottom: 0;
    }

    p {
      font-weight: 200;
      margin-top: 5px;
      color: #5b708b;
      font-size: 22px;
    }
    .button {
      cursor: pointer;
      background: linear-gradient(
        90deg,
        var(--color-primary),
        rgba(65, 146, 255, 1) 100%
      );

      border-radius: 20px;
      padding: 7px 0;
      width: 200px;
      display: flex;
      justify-content: center;

      p {
        color: white;
        margin: 0;
        font-weight: 300;
      }
    }
  }
}

.landing-page-graphic {
  position: absolute;
  top: 0;
  right: 0;
  left: 50%;
  overflow-x: hidden;
  z-index: -1;
  padding-top: 15em;
}

.gradient {
  display: none;
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .landing-page {
    padding: 0 70px;
  }

  .landing-page-graphic {
    display: none;
  }

  .gradient {
    display: unset;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    .blue {
      position: absolute;
      display: unset;
      z-index: -1;
      width: 360px;
      bottom: 0;
      right: -120px;
    }

    .orange {
      position: absolute;
      display: unset;
      top: -70px;
      left: -180px;
      z-index: -1;
      width: 360px;
    }
  }
}

@media (max-width: 767.98px) {
  .landing-page {
    .text {
      .button {
        width: 191px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .landing-page {
    padding: 0 20px;
    .text {
      br {
        display: none;
      }
      .button {
        width: 151px;
      }
    }
  }
}
