.makerspace {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: var(--large-padding);
  height: 100vh;
  max-width: 100vw;
  margin-top: 5em;
  background-image: url("../assets/makerspace-background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .text {
    width: 380px;
    color: white;
    text-align: left;

    h1 {
      margin: 0;
    }

    p {
      font-size: 16px;
    }

    .blue-text {
      color: #3d8efb;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .makerspace {
    padding: var(--small-padding);
  }
}

@media (max-width: 767.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .makerspace {
    padding: var(--mobile-padding);
  }
}
