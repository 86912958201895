.featured {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--large-padding);
  flex-direction: column;
  margin-top: 5em;

  .images {
    padding: 0;
    img {
      margin: 0 10px 10px 10px;
      max-width: 200px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .featured {
    padding: var(--small-padding);
  }
}

@media (max-width: 767.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .featured {
    padding: var(--mobile-padding);

    .images {
      img {
        max-width: 160px;
      }
    }
  }
}
