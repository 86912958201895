.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;

  .links {
    display: flex;

    p {
      cursor: pointer;
      font-weight: 200;
      margin: 0;
      margin-left: 20px;
      font-size: 17px;
    }
  }

  .logo {
    height: 45px;
    cursor: pointer;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991.98px) {
  .navbar {
    padding: 0 70px;
  }
}

@media (max-width: 767.98px) {
  .navbar {
    .links {
      display: none;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .navbar {
    padding: 0 20px;
  }
}
