:root {
  --color-primary: #3d8efb;
  --color-secondary: #5b708b;
  --large: 64px;
  --medium: 32px;
  --small: 16px;
  --large-padding: 0 150px;
  --small-padding: 0 70px;
  --mobile-padding: 0 20px;
}
